<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">
    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        Cuéntanos sobre tus aptitudes para conocer a detalle tu perfil de trabajo
      </div>
    </div>


    <ValidationObserver ref="formstepPiJ" tag="div"  class="ppi_contentZone spacerRowTop48" v-slot="{ handleSubmit, valid, passed }">

      <q-form 
        ref="myFormPij"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPijReset"
        class="formDetailZone "
      >

        <div class="dataSection_container" style="border-bottom: 1px solid #DCDBE1;">
          <div class="dataSection_title">Escolaridad</div>
          <div class="datasectionItem dataSection_separadorx" :style="(is_Mobile === true || $q.screen.lt.md) ? '' : 'max-width:750px'">

              <div class="row q-gutter-md" style="margin: 0px;">

                <!-- Campo de grado de estudios -->
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'margin-left: 0px;max-width: 48% !important;'"
                >

                  <ValidationProvider 
                    tag="div"
                    ref="fldx_grado" name="grado" :rules=" { required: true, oneOf: z_allowGrados }" :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect"
                          :class="{'fieldError':invalid && validated }"
                        >último grado de estudios</div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-select
                        name="grado"
                        filled
                        :dense="true"
                        v-model="frmData.grado"
                        :label="(frmData.grado ==='' || frmData.grado === null) ? 'Elige una opción' : ''"
                        :options="cat_Grados.dataCatalog"
                        option-value="id"
                        option-label="nombre"
                        emit-value
                        map-options
                        :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? errors[0].replace('grado','Último grado de estudios').replace('El campo','') : '' "
                      >
                        <template v-slot:append>
                          <q-icon v-if="frmData.grado !== null" class="cursor-pointer" name="clear" @click.stop="frmData.grado = null"/>
                        </template>
                        <template v-slot:option="scope">
                          <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                            <q-item-section>
                              <q-item-label>
                                {{scope.opt.nombre}}
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>

                      </q-select>
                  </ValidationProvider>

                </div>

                <!-- Campo de titulo -->
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width: 48% !important;'"
                >

                  <ValidationProvider 
                    tag="div"
                    ref="fldx_grado" name="titulo" :rules=" { required: true, oneOf: z_allowTitulos }" :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect"
                          :class="{'fieldError':invalid && validated }"
                        >Título o certificado</div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-select
                        name="titulo"
                        filled
                        :dense="true"
                        v-model="frmData.titulo"
                        :label="(frmData.titulo ==='' || frmData.titulo === null) ? 'Elige una opción' : ''"
                        :options="cat_Titulos.dataCatalog"
                        option-value="id"
                        option-label="nombre"
                        emit-value
                        map-options
                        :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? errors[0].replace('titulo','Título o certificado').replace('El campo','') : '' "
                      >
                        <template v-slot:append>
                          <q-icon v-if="frmData.titulo !== null" class="cursor-pointer" name="clear" @click.stop="frmData.titulo = null"/>
                        </template>
                        <template v-slot:option="scope">
                          <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                            <q-item-section>
                              <q-item-label>
                                {{scope.opt.nombre}}
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>

                      </q-select>

                  </ValidationProvider>

                </div>

              </div>

              <div class="row q-gutter-md" style="margin: 0px;">
                <!-- Campo de Escuela -->
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'margin-left: 0px;max-width: 48% !important;'"
                >
                  <ValidationProvider 
                    tag="div"
                    ref="fldx_grado" name="escuela" :rules=" { required: true, cannaTextOtros:true, emojiFree:true, min:1, max:145 }" :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect"
                        :class="{'fieldError':invalid && validated }"
                      >Nombre de la escuela</div>
                      <div class="requiredLabel text-right" >&nbsp;</div>
                    </div>
                    <q-input
                      filled
                      name="escuela"
                      v-model="frmData.escuela"
                      hint=""
                      :dense="true"
                      :label="(frmData.escuela==='' || frmData.escuela===null)?'Escribe el nombre de tu escuela':''"
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ?  errors[0].replace('escuela','Nombre de la escuela').replace('El campo','') : ''"
                    >
                      <template v-slot:append>
                        <q-icon v-if="frmData.escuela !== null" class="cursor-pointer" name="clear" @click.stop="frmData.escuela = null"/>
                      </template>
                    </q-input>
                  </ValidationProvider>

                </div>

                <!-- Campo de carrera  -->
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width: 48% !important;'"
                >
                  <ValidationProvider 
                    tag="div" ref="fldx_carrera" name="carrera" :immediate="false" 
                    :rules=" { required: fieldCarrera_Required, cannaTextOtros:true, emojiFree:true, min:1, max:145 }"
                    v-slot="{ errors, invalid, validated  }"
                  >
                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                        ¿Qué carrera estudiaste?
                      </div>
                      <div class="requiredLabel text-right" >&nbsp;</div>
                    </div>
                    <q-input
                      :disable="(fieldCarrera_Required===false)? true : false"
                      filled
                      name="carrera"
                      v-model="frmData.carrera"
                      hint=""
                      :dense="true"
                      :label="(frmData.carrera==='' || frmData.carrera===null)?'Escribe el nombre de tu carrera':''"
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ?  errors[0].replace('carrera','¿Qué carrera estudiaste?').replace('El campo','') : ''"
                    >
                      <template v-slot:append>
                        <q-icon v-if="frmData.carrera !== null" class="cursor-pointer" name="clear" @click.stop="frmData.carrera = null"/>
                      </template>
                    </q-input>
                  </ValidationProvider>
                </div>
              </div>

              <div class="row q-gutter-md" style="margin: 0px;">
                <!-- Campos de Fecha de inicio -->
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'margin-left: 0px;max-width: 48% !important;'"
                >

                  <ValidationProvider
                    :rules=" { required:true, cannaQuest_DateIsValid:true } " :immediate="true" ref="fldx_fechainicio" name="fecha_inicio" 
                    v-slot="{ errors, invalid, validated  }"
                    tag="div" class="row justify-between"
                  >
                    <input type="hidden" name="fecha_inicio"  v-model="frmData.fechaInicio.fecha">
                    <div class="col-12" style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect"
                        :class="{'fieldError':invalid && validated }"
                      >Fecha de inicio</div>
                      <div class="requiredLabel text-right" >&nbsp;</div>
                    </div>
                    <!-- año list_dtRefMeses -->
                    <div class="col-6 row" style="margin-top: 8px !important;max-width: 48%;">

                      <div class="col-12" style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect"
                          :class="{'fieldError':invalid && validated }"
                        >Año</div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <div class="col-12">
                        <q-select
                          name="fechainicio_year"
                          filled
                          :dense="true"
                          v-model="frmData.fechaInicio.year"
                          :label="(frmData.fechaInicio.year ==='' || frmData.fechaInicio.year === null) ? 'Año' : ''"
                          :options="catdata_years"
                          option-value="value"
                          option-label="label"
                          emit-value
                          map-options
                          :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                          :error="invalid && validated"
                          @blur="pij_doCheckFechasInicio"
                        >
                          <template v-slot:append>
                            <q-icon name="clear" class="cursor-pointer" v-if="frmData.fechaInicio.year !== null" @click.stop="frmData.fechaInicio.year = null"/>
                          </template>

                          <template v-slot:option="scope">
                            <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                              <q-item-section>
                                <q-item-label>
                                  {{scope.opt.label}}
                                </q-item-label>
                              </q-item-section>
                            </q-item>
                          </template>

                        </q-select>
                      </div>
                    </div>

                    <!-- mes -->
                    <div class="col-6 row canna_forma_campo_container" style="margin-top: 8px !important; max-width: 48%;">
                      <div class="col-12" style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect"
                          :class="{'fieldError':invalid && validated }"
                        >Mes</div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <div class="col-12">

                        <q-select
                          name="fechainicio_month"
                          filled
                          :dense="true"
                          v-model="frmData.fechaInicio.month"
                          :label="(frmData.fechaInicio.month ==='' || frmData.fechaInicio.month === null) ? 'Mes' : ''"
                          :options="list_refMesesSelect"
                          option-value="value"
                          option-label="label"
                          emit-value
                          map-options
                          :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                          :error="invalid && validated"
                          @blur="pij_doCheckFechasInicio"
                        >
                        <!--  :error-message="(errors[0] !==undefined) ? errors[0].replace('tipolicencia','Tipo de licencia').replace('El campo','') : '' " -->

                          <template v-slot:append>
                            <q-icon name="clear" class="cursor-pointer" v-if="frmData.fechaInicio.month !== null" @click.stop="frmData.fechaInicio.month = null"/>
                          </template>

                          <template v-slot:option="scope">
                            <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                              <q-item-section>
                                <q-item-label>
                                  {{scope.opt.label}}
                                </q-item-label>
                              </q-item-section>
                            </q-item>
                          </template>

                        </q-select>

                      </div>
                    </div>

                    <template v-if="(invalid && validated && errors[0]!==undefined)">
                      <div class="col-12" style="margin-top: 0px;">
                        <div class="q-field--error q-field__bottom" 
                          style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center;"
                        >
                        {{ errors[0].replace('fecha_inicio', 'Fecha de inicio').replace('El campo','') }}
                        </div>
                      </div>
                    </template>

                  </ValidationProvider>

                </div>

                <!-- Campos Fecha de fin  -->
                <div class="col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width: 48% !important;'"
                >
                  <ValidationProvider
                      :rules=" { 
                        required:true, 
                        oneOf: z_allowfinEstudios
                      } " 
                    :immediate="false" ref="fldx_tipoFin" name="fecha_fintipo" 
                    v-slot="{ errors, invalid, validated  }"
                    tag="div" class="row"
                  >
                    <!--  
                      :rules=" { 
                        required:true, 
                        oneOf: z_allowfinEstudios,
                        cannaQuest_escuelaFechaFinVsInicio:@fecha_inicio
                      } " 
                    -->
                    <div class="col-12" style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect"
                        :class="{'fieldError':invalid && validated }"
                      >Fecha de fin</div>
                      <div class="requiredLabel text-right" >&nbsp;</div>
                    </div>
                    <input type="hidden" name="fecha_fintipo"  v-model="frmData.tipoFin">

                    <div class="col-12 row" style="margin-left: -10px;">

                      <q-option-group
                        class="canna_optionGroup_A"
                        name="tipofinestudio"
                        type="radio"
                        keep-color
                        :options="[
                          { label: 'Actualmente estoy estudiando', value: 751, color:(invalid && validated && errors[0] !==undefined || frmData.tipoFin===null ) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
                          { label: 'Mi último nivel de escolaridad quedó trunco', value: 752, color:(invalid && validated && errors[0] !==undefined || frmData.tipoFin===null) ? 'cannaRadioError' : 'canna_ColorRadio' },
                          { label: 'He concluido mi último grado de estudios', value: 753, color:(invalid && validated && errors[0] !==undefined || frmData.tipoFin===null) ? 'cannaRadioError' : 'canna_ColorRadio' }
                        ]"
                        v-model="frmData.tipoFin"
                      />
                      <!-- 
                        || frmData.tipoFin===null 
                      -->
                    </div>

                    <template v-if="(invalid && validated && errors[0] !==undefined)">
                      <div class="q-field--error q-field__bottom" 
                        style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center;"
                      >
                      {{ errors[0].replace('fecha_fintipo','Fecha de fin').replace('El campo','') }}
                      </div>
                    </template>
                  
                  </ValidationProvider>

                  <template v-if="(frmData.tipoFin===753)"> <!-- Si la fecha fin de estudios es de tipo  753 -->
                    <ValidationProvider
                      :rules=" {
                        required: (frmData.tipoFin===753) ? true : false , 
                        cannaQuest_DateIsValid:true,
                        cannaQuest_escuelaFechaFinVsInicio:{tipoFinUse:frmData.tipoFin, fechaIni:'@fecha_inicio'}
                      }"
                      :immediate="true" ref="fldx_fechafin" name="fecha_final" 
                      v-slot="{ errors, invalid, validated  }" tag="div" class="row justify-between"
                      :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'margin-left: 10px;'" 
                    >
                      <input type="hidden" name="fecha_final"  v-model="frmData.fechaFin.fecha">
                      <!-- año list_dtRefMeses -->
                      <div class="col-6 row" style="margin-top: 8px !important;max-width: 48%;">
                        <div class="col-12" style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel labelFx_Field text-left noselect"
                            :class="{'fieldError':invalid && validated }"
                          >Año</div>
                          <div class="requiredLabel text-right" >&nbsp;</div>
                        </div>
                        <div class="col-12">
                          <q-select
                            name="fechafin_year"
                            filled
                            :dense="true"
                            v-model="frmData.fechaFin.year"
                            :label="(frmData.fechaFin.year ==='' || frmData.fechaFin.year === null) ? 'Año' : ''"
                            :options="catdata_years"
                            option-value="value"
                            option-label="label"
                            emit-value
                            map-options
                            :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                            :error="invalid && validated"
                            @blur="pij_doCheckFechasFin"
                          >
                            <template v-slot:append>
                              <q-icon name="clear" class="cursor-pointer" v-if="frmData.fechaFin.year !== null" @click.stop="frmData.fechaFin.year = null"/>
                            </template>

                            <template v-slot:option="scope">
                              <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                                <q-item-section>
                                  <q-item-label>
                                    {{scope.opt.label}}
                                  </q-item-label>
                                </q-item-section>
                              </q-item>
                            </template>

                          </q-select>
                        </div>
                      </div>

                      <!-- mes -->
                      <div class="col-6 row canna_forma_campo_container" style="margin-top: 8px !important; max-width: 48%;">
                        <div class="col-12" style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel labelFx_Field text-left noselect"
                            :class="{'fieldError':invalid && validated }"
                          >Mes</div>
                          <div class="requiredLabel text-right" >&nbsp;</div>
                        </div>
                        <div class="col-12">

                          <q-select
                            name="fechafin_month"
                            filled
                            :dense="true"
                            v-model="frmData.fechaFin.month"
                            :label="(frmData.fechaFin.month ==='' || frmData.fechaFin.month === null) ? 'Mes' : ''"
                            :options="list_refMesesSelect"
                            option-value="value"
                            option-label="label"
                            emit-value
                            map-options
                            :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                            :error="invalid && validated"
                            @blur="pij_doCheckFechasFin"
                          >
                            <template v-slot:append>
                              <q-icon name="clear" class="cursor-pointer" v-if="frmData.fechaFin.month !== null" @click.stop="frmData.fechaFin.month = null"/>
                            </template>

                            <template v-slot:option="scope">
                              <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                                <q-item-section>
                                  <q-item-label>
                                    {{scope.opt.label}}
                                  </q-item-label>
                                </q-item-section>
                              </q-item>
                            </template>

                          </q-select>

                        </div>
                      </div>

                      <template v-if="(invalid && validated && errors[0] !==undefined)">
                        <div class="col-12" style="margin-top: 0px;">
                          <div class="q-field--error q-field__bottom" 
                            style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center;"
                          >
                          {{ 
                            (errors[0].indexOf('El año y mes de la fecha de fin')>=0) ? 
                              errors[0].replace('fecha_final','').replace('El campo','') : 
                                errors[0].replace('fecha_final', 'Fecha de fin de estudios').replace('El campo','') 
                          }}
                          </div>
                        </div>
                      </template>

                    </ValidationProvider>

                  </template>

                </div>

              </div>

          </div>
        </div>

        <!-- Validador general de la forma --> 
        <ValidationProvider 
          tag='div'
          :rules=" { required: true,cannaQuestPuestosMin:1, cannaQuestPuestosMax:6 }"
          :immediate="true"
          :ref="'fldx_idiomasall'"
          name="idiomasAll" v-slot="{ errors, invalid, validated }"
        >
          <input type="hidden" name="idiomasAll" v-model="idiomas_fullVals">
          <template v-if="(invalid && validated && errors[0] !==undefined)">

            <div class="notifyZone noselect notifyZoneRemove" style="margin-top:24px; margin-bottom:0px; margin-left: 0px;width: 100% !important;">
              <div style="flex: 1 1 0%; justify-content: center;padding: 0px 20px;display: flex;align-items: center;">
                  {{ pij_idiomasAllMsg(errors[0]) }}
              </div>
              <div style="margin-right: 14px; display:none;">
                  <div class="closenotifyZoneIcon action_item" @click.stop="pij_doResetIdiomasAll">
                    <q-icon name="clear" style="color:#ffffff; font-size: 20px;" />
                  </div>
              </div>
            </div>

          </template>
        </ValidationProvider>

        <div class="dataSection_container spacerRowTop48">
          <div class="dataSection_title">¿Qué idiomas hablas?</div>
          <div class="datasectionItem dataSection_separadorx" style="margin-left: -10px;">
            <div class="row" :style="(is_Mobile === true || $q.screen.lt.md) ? '' : 'max-width:650px'">

              <template v-for="(idioma, index) in cat_Idiomas.dataCatalog">
                <div class="col-xs-12 col-md-6" :key="'SocialesChk_' + index">
                  <template v-if="idioma.id===8">

                    <q-checkbox v-model="frmData.idiomas"
                      :label="idioma.nombre" color="cannaMainColor" :val="idioma.id" 
                      @input="pij_changeIdiomaOtros"
                    />

                    <div style="padding-left: 10px;">
                      <ValidationProvider :rules=" { 
                        required: (val_idiomasHasOtros===false) ? false : true, min:4, max:40, cannaTextOtros:true 
                      }"
                        :immediate="true"
                        :ref="'fldx_idiomasOtro'"
                        name="idiomasOtro" v-slot="{ errors, invalid, validated  }"
                      >
                        <q-input
                          filled
                          name="idiomasOtro"
                          maxlength="40"
                          v-model="frmData.idiomas_otros"
                          hint=""
                          :dense="true"
                          :label="(frmData.idiomas_otros==='' || frmData.idiomas_otros=== null)?'Por favor, especifica':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errors[0].replace('idiomasOtro','Otro (descripción)') : '' "
                          :disable="(val_idiomasHasOtros===false)? true: false"
                        />
                      </ValidationProvider>
                    </div>
                  </template>
                  <template v-else>
                    <!-- :disable="(idioma.id === 3) ? true:false " -->
                    <q-checkbox v-model="frmData.idiomas"
                      :label="idioma.nombre" color="cannaMainColor" :val="idioma.id" 
                    />
                  </template>
                </div>
              </template>
              
            </div>
          </div>
        </div>

        <div class="ppi_pz_footer">
          <div class="rowButtons">

            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps 
                :disable="((passed || valid) )? false : true"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>
    </ValidationObserver>

  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from './../../../../boot//axios' // APP code 
import logOut_Mixin from './../../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import { helperCatsFechas_Mixin } from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'

const tplpij_Data = {
  grado:null, 
  titulo: null, 
  escuela:null, 
  carrera:null, 
  fechaInicio:{ fecha:'' , year: null, month: null },
  tipoFin: null,
  fechaFin:{ fecha:'' , year: null, month: null },
  idiomas: [],
  idiomas_otros: null
}

export default {
  name: 'psi_screen_escolaridad',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    helperCatsFechas_Mixin,
    questionario_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      //-----------
      myDifferify: differify,
      //-----------
      zloadingData: false,
      processingData: false,
      //-----------
      catdata_years: [],
      catFinEstudios:[],
      //-----------
      z_allowGrados: [],
      z_allowTitulos: [],
      z_allowfinEstudios: [],
      profileRecords:[], //Los registros previamente guardados en este perfil 
      frmDataOriginal: JSON.parse(JSON.stringify(tplpij_Data)),
      frmData: JSON.parse(JSON.stringify(tplpij_Data))
    }
  },
  computed: {
    cat_Idiomas () { // catalogo id = 2 Idiomas (otro : 8)
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(2) : this.getHelperCat(2)
    },
    cat_Grados () { // catalogo id = 21 Nivel académico (grados de estudio)
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(21) : this.getHelperCat(21)
    },
    cat_Titulos () { // catalogo id = 22 Comprobante de estudios (títulos)
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(22) : this.getHelperCat(22)
    },
    cat_dataEscolaridad () { // catalogo id = 59 Datos escolaridad
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(59) : this.getHelperCat(59)
    },
    cat_InicioEstudios () { // catalogo id = 60 Inicio de estudios // 750 Pide fecha inicial 
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(61) : this.getHelperCat(61)
    },
    cat_FinEstudios () { // catalogo id = 61 Fin estudios // 753 Pide fecha final 
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(61) : this.getHelperCat(61)
    },
    fieldCarrera_Required () { 
      var retval = true
      var currval =  this.frmData.grado 
       var gradosNOt = [141,142,143]
      retval = (currval === null || (gradosNOt.indexOf(currval)>=0) ) ? false : true 
      return retval
    },
    idiomas_fullVals (){ 
      var prueba = this.frmData.idiomas.slice(0) 
      var pruebab = []
      return prueba.concat(pruebab)
    },
    val_idiomasHasOtros () {
      var test = false
      if (this.frmData.idiomas !== undefined) {
        if (this.frmData.idiomas.length) {
          test = (this.frmData.idiomas.indexOf(8) >= 0) ? true : false
        }
      }
      return test
    },
    mainErrors () {
      var countx = 0
      if (this.zinitScreen) {
        try {
          if (this.$refs.formstepPiJ) {
            if (this.$refs.formstepPiJ.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepPiJ.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepPiJ.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }


  },
  watch: {
    'frmData.grado': function( newVal, oldVal ){
      if (newVal!==oldVal){
        var gradosNOt = [141,142,143]
        if (gradosNOt.indexOf(newVal)>=0){ 
          this.frmData.carrera = null
          if (this.$refs.fldx_carrera){
            this.$refs.fldx_carrera.reset()
          }
        }
        this.$forceUpdate()
        setTimeout(() => {
          if (this.$refs.fldx_carrera){
            this.$refs.fldx_carrera.validate()
          }
        }, 300)
      }
    },
    'frmData.fechaInicio.year': function(newVal, oldVal){
      if(newVal!==oldVal){ 
        this.pij_updateFechaInicio()
      }
    },
    'frmData.fechaInicio.month': function(newVal, oldVal){
      if(newVal!==oldVal){ 
        this.pij_updateFechaInicio()
      }
    },
    'frmData.fechaFin.year': function(newVal, oldVal){
      if(newVal!==oldVal){ 
        this.pij_updateFechaFin()
      }
    },
    'frmData.fechaFin.month': function(newVal, oldVal){
      if(newVal!==oldVal){ 
        this.pij_updateFechaFin()
      }
    },
    'frmData.tipoFin': function(newVal, oldVal){
      if(newVal!==oldVal){
      // 751	Actualmente estoy estudiando
      // 752	Mi último nivel de escolaridad quedó trunco
      // 753	He concluido mi último grado de estudios
        if (newVal!==753){
          this.frmData.fechaFin = { fecha:'' , year: null, month: null }
          this.$forceUpdate()
        }
      }
    }
  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_escolaridad --   test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal/uniformes-a'
      this.$cannaDebug('-- psiPage_escolaridad --   do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    onStepFormPijReset () { 

    },
    pij_createYearDataList(){ 
      var myDataYears = []
      var fechaz = 1946
      var fechax = new Date()
      var yearinitial = fechax.getUTCFullYear()
      for (var i=1946;i<=(yearinitial);i++){  //for (var i=1946;i<=(yearinitial+4);i++){
        myDataYears.push({ label:'' + (i) , value: (i)})
      }
      myDataYears.reverse();
      this.catdata_years = myDataYears
    },
    pij_doCheckFechasInicio(){ 
      if (this.$refs.fldx_fechainicio){
        this.$refs.fldx_fechainicio.validate()
      }
    },
    pij_updateFechaInicio() {
      var fullfecha = ''
      if (this.frmData.fechaInicio.year !== null ){
        fullfecha+= this.frmData.fechaInicio.year
        if (this.frmData.fechaInicio.month !== null ){
          //fullfecha+= '-' 
          //fullfecha+= (this.frmData.fechaInicio.month<=9) ? '0' + this.frmData.fechaInicio.month : this.frmData.fechaInicio.month
          fullfecha = this.$moment([this.frmData.fechaInicio.year, (this.frmData.fechaInicio.month - 1)]).startOf('month').format('YYYY-MM-DD')
        }
      }
      this.frmData.fechaInicio.fecha = fullfecha
      this.$forceUpdate()
      if (this.$refs.fldx_fechainicio){
        this.$refs.fldx_fechainicio.validate()
      }
    },
    pij_doCheckFechasFin(){
      if (this.$refs.fldx_fechafin){
        this.$refs.fldx_fechafin.validate()
      }
    },
    pij_updateFechaFin() {
      var fullfecha = ''
      if (this.frmData.fechaFin.year !== null ){
        fullfecha+= this.frmData.fechaFin.year
        if (this.frmData.fechaFin.month !== null ){
          // fullfecha+= '-'
          // fullfecha+= (this.frmData.fechaFin.month<=9) ? '0' + this.frmData.fechaFin.month : this.frmData.fechaFin.month
          fullfecha = this.$moment([this.frmData.fechaFin.year, (this.frmData.fechaFin.month - 1)]).endOf('month').format('YYYY-MM-DD')
        }
      }
      this.frmData.fechaFin.fecha = fullfecha
      this.$forceUpdate()
      if (this.$refs.fldx_fechafin){
        this.$refs.fldx_fechafin.validate()
      }
    },
    pij_doResetIdiomasAll:function(){ 
      if (this.$refs.fldx_idiomasall){
        this.$refs.fldx_idiomasall.reset()
      }
    },
    pij_idiomasAllMsg: function( errorx ){ 
      if (errorx === undefined || errorx === null || errorx ===''){ 
        return ''
      }
      var strbase = errorx.replace('idiomasAll','').replace('El campo','')
      if (strbase.trim() ==='es obligatorio'){ 
        strbase = '¡Espera! Debes seleccionar mínimo 1 opción en está sección.'
      }
      //var test == (errors[0] !==undefined) ? errors[0] : ''
      return strbase
    },
    pij_changeIdiomaOtros: function (valuex, event) {
      this.$cannaDebug('-- psiPage_escolaridad --   change Idioma Otros --- ')
      var test = 11
      var currval = valuex 
      if (currval.indexOf(8) < 0) {
        this.frmData.idiomas_otros = null
        this.$refs.fldx_idiomasOtro[0].reset()
      }
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.fldx_idiomasOtro[0].validate().then((response) => { 
          test = 22
        })
      }, 200)
    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_escolaridad --   do Submit Data --- ' + newRuta)
      var test = 11 , tmpArrZ = null, tmpArrW = null 
      var toAdd = [], toRemove = [], toPatch = []
      var allowNextPage = false 
      if (this.mainErrors > 0) {
        return false
      }
      this.showLoadx(true, 1)

      var originalRecords = JSON.parse(JSON.stringify(this.profileRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 
      var testA = this.myDifferify.compare(originalValues, newValues)
      if (testA.changes > 0 ) { // Que existan valores...!

        // grado de estudios
        if (testA._.grado.status === 'MODIFIED') {
          var item = testA._.grado 
          if (item.status === 'ADDED') {
            toAdd.push(item.current)
          } else if (item.status === 'MODIFIED') {
            if (this.profileRecords.length > 0 &&  (item.original!=='' && item.original!==null)){ 
              toRemove.push(item.original)
            }
            toAdd.push(item.current)
          } else if (item.status === 'DELETED') { // Raro q aplique 
            toRemove.push(item.original)
          }
        }
        // titulo
        if (testA._.titulo.status === 'MODIFIED') {
          var item = testA._.titulo 
          if (item.status === 'ADDED') {
            toAdd.push(item.current)
          } else if (item.status === 'MODIFIED') {
            if (this.profileRecords.length > 0 &&  (item.original!=='' && item.original!==null)){ 
              toRemove.push(item.original)
            }
            toAdd.push(item.current)
          } else if (item.status === 'DELETED') { // Raro q aplique 
            toRemove.push(item.original)
          }
        }
        //------
        //------
        // Nombre de  escuela
        if (testA._.escuela.status === 'MODIFIED') {

          const EscuelaIDUse = 748 
          var item = testA._.escuela 

          if (item.status === 'ADDED') {
            toAdd.push({ catalogo_id: EscuelaIDUse, otro: '' + item.current })
          } else if (item.status === 'MODIFIED') {
            tmpArrW = toRemove.indexOf(EscuelaIDUse)
            tmpArrZ = this.profileRecords.filter(item => item.catalogo_id === EscuelaIDUse) // para verificar q que exista
            if (tmpArrZ.length > 0 && tmpArrW < 0) { // Solo si existe el registro ..! para aplicar en PATCH
              toPatch.push([EscuelaIDUse, '' + item.current ])
            } else if (tmpArrZ.length<=0) { 
              toAdd.push({ catalogo_id: EscuelaIDUse, otro: '' + item.current })
            }
          } else if (item.status === 'DELETED') { // Raro q aplique 
            toRemove.push(EscuelaIDUse)
          }
        }
        // Nombre de carrera 
        if (testA._.carrera.status === 'MODIFIED') {
          const carreraIDUse = 749 
          var item = testA._.carrera 
          if (item.status === 'ADDED') {
            toAdd.push({ catalogo_id: carreraIDUse, otro: '' + item.current })
          } else if (item.status === 'MODIFIED') {
            tmpArrW = toRemove.indexOf(carreraIDUse)
            tmpArrZ = this.profileRecords.filter(item => item.catalogo_id === carreraIDUse) // para verificar q que exista
            if (tmpArrZ.length > 0 && tmpArrW < 0) { // Solo si existe el registro ..! para aplicar en PATCH
              if (item.current === null ){
                toRemove.push(carreraIDUse)
              } else {
                toPatch.push([carreraIDUse, '' + item.current ])
              }
            } else if (tmpArrZ.length<=0) { 
              toAdd.push({ catalogo_id: carreraIDUse, otro: '' + item.current })
            }
          } else if (item.status === 'DELETED') { // Raro q aplique 
            toRemove.push(carreraIDUse)
          }
        }
        //------
        // Fecha inicio 
        if (testA._.fechaInicio._.fecha.status === 'MODIFIED') {
          const fxini_IDUse = 750 
          var item = testA._.fechaInicio._.fecha 
          if (item.status === 'ADDED') {
            toAdd.push({ catalogo_id: fxini_IDUse, otro: '' + item.current })
          } else if (item.status === 'MODIFIED') {
            tmpArrW = toRemove.indexOf(fxini_IDUse)
            tmpArrZ = this.profileRecords.filter(item => item.catalogo_id === 750) // Tiene que existir
            if (tmpArrZ.length > 0 && tmpArrW < 0) { // Solo si existe el registro ..! para aplicar en PATCH
              toPatch.push([fxini_IDUse, '' + item.current ])
            } else if (tmpArrZ.length<=0) { 
              toAdd.push({ catalogo_id: fxini_IDUse, otro: '' + item.current })
            }
          } else if (item.status === 'DELETED') { // Raro q aplique 
            toRemove.push(fxini_IDUse)
          }
        }
        // Tipo de Fin Estudios
        const fechaFinUseID = 753 
        if (testA._.tipoFin.status === 'MODIFIED') {
          var item = testA._.tipoFin 
          if (item.status === 'ADDED') {
            if (item.current === fechaFinUseID) {
              toAdd.push({ catalogo_id: item.current , otro: '' + this.frmData.fechaFin.fecha })
            } else {
              toAdd.push ( item.current)
            }
          } else if (item.status === 'MODIFIED') {
            if (item.original!==null){ 
              toRemove.push(item.original)
            }
            if (item.current === fechaFinUseID) {
              toAdd.push({ catalogo_id: item.current , otro: '' + this.frmData.fechaFin.fecha })
            } else {
              toAdd.push ( item.current)
            }
          } else if (item.status === 'DELETED') { // Raro q aplique 
            toRemove.push(item.current)
          }
        }
        // FechaFin estudios si aplica patch solamente 
        if (testA._.fechaFin._.fecha.status === 'MODIFIED') {
          var tmpArrW = toRemove.indexOf(753)
          tmpArrZ = this.profileRecords.filter(item => item.catalogo_id === 753) // Tiene que existir
          if (tmpArrZ.length > 0 && tmpArrW < 0) { // Solo si existe el registro ..! para aplicar en PATCH
            toPatch.push([fechaFinUseID, this.frmData.fechaFin.fecha])
          }
        }
        // Idiomas 
        if (testA._.idiomas.status === 'MODIFIED') {
          testA._.idiomas._.forEach(item => {
            if (item.status === 'ADDED') {
              if (item.current === 8) {
                toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.idiomas_otros })
              } else {
                toAdd.push(item.current)
              }
            } else if (item.status === 'MODIFIED') {
              if (item.current === 8) {
                if (item.current!==null && item.current===8 && item.original!==8){
                  if (toRemove.indexOf(item.original)<0) {  // verificamos q no se repita
                    toRemove.push(item.original)
                  }
                  var checkAddOtro = this.profileRecords.findIndex(itemFx => itemFx.catalogo_id===item.current)
                  if (checkAddOtro<0){ 
                    toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.idiomas_otros })  
                  }
                } else {
                  toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.idiomas_otros })
                }
              } else {
                if (item.current!==null && item.current!== item.original){
                if (toRemove.indexOf(item.original)<0) {  // verificamos q no se repita
                  toRemove.push(item.original)
                }
                  var checkAddIdioma = this.profileRecords.findIndex(itemFx => itemFx.catalogo_id===item.current)
                  if (checkAddIdioma<0){ 
                    toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.idiomas_otros })  
                  }
                } else {
                  toAdd.push(item.current)
                }
              }
            } else if (item.status === 'DELETED') {
              var indexFrm = this.frmData.idiomas.indexOf(item.original)
              var indexOriginal = this.frmDataOriginal.idiomas.indexOf(item.original)
              if ( indexFrm< 0  && indexOriginal>=0 ){ // Checamos q no se haya modificado por otro id (pos 0 en el array)
                if (toRemove.indexOf(item.original)<0) {  // verificamos q no se repita
                  toRemove.push(item.original)
                }
              }
            }
          })
        }
        // idiomas_otros si aplica patch 
        if (testA._.idiomas_otros.status === 'MODIFIED') {
          var tmpArrW = toRemove.indexOf(8)
          tmpArrZ = this.profileRecords.filter(item => item.catalogo_id === 8) // Tiene que existir
          if (tmpArrZ.length > 0 && tmpArrW < 0) { // Solo si existe el registro ..! para aplicar en PATCH
            toPatch.push([tmpArrZ[0].catalogo_id, this.frmData.idiomas_otros])
          }
        }
        // -----------
        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [],  rmovex: [], patch: [], addx: [] }
        var reqErr = { action: 0, rmovex: 0, patch: 0, addx: 0 }
        var mcModel = this.getModelRequest('profileFiltro')
        // ----------------------
        // if (test!=22) {
          //alert('Salida de escape')
          //return false 
        // }
        // ----------------------
        // Registros a eliminar...! 
        if (toRemove.length > 0) {
          var toDelReqs = []
          for (const delRC of toRemove) {
            toDelReqs.push(this.createAxiosConfig ('remove', mcModel, { delimiters: [delRC] }, true, null))
          }
          for (const delRQx of toDelReqs) {
            if (reqErr.rmovex <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataRemove', reqRes.rmovex, delRQx).then((resx) => {
                var tmpresponseData = reqRes.rmovex[reqRes.rmovex.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.rmovex += 1
                }
              })
            }
          }
          if (reqErr.rmovex > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.rmovex[0], reqErr.rmovex)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se hace path 
        if (toPatch.length > 0) {
          var toPatchReqs = []
          var patchedReqs = 0
          for (const pxRC of toPatch) {
            toPatchReqs.push(this.createAxiosConfig ('patch', mcModel, { 
              delimiters: [pxRC[0]],
              data: { otro: '' + pxRC[1] }
            }, true, null))
          }
          for (const patchRQx of toPatchReqs) {
            if (reqErr.patch <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataPatch', reqRes.patch, patchRQx).then((resx) => {
                patchedReqs+=1
                var tmpresponseData = reqRes.patch[reqRes.patch.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.patch += 1
                }
              })
            }
          }
          if (reqErr.patch > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.patch[0], reqErr.patch)
            //--------------
            if (patchedReqs>0){
              await this.initFormData().then( respx => {
                this.showLoadx(false, 1)
              })
              return false 
            }
            //--------------
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se agrega 
        if (toAdd.length > 0) {
          var toAddReqs = []
          var addedReqs = 0
          for (const addRC of toAdd) {
            var addParams = null
            var testObject = (addRC !== null && typeof addRC === 'object' && Array.isArray(addRC) === false) ? true : false 
            if (testObject === true) { // Objetos como (Otros) que llevan 2 valores
              addParams = addRC
            } else {
              addParams = {catalogo_id: addRC}
            }
            toAddReqs.push(this.createAxiosConfig ('post', mcModel, { delimiters: [], data: addParams }, true, null))
          }
          for (const addRQx of toAddReqs) {
            if (reqErr.addx <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataAdd', reqRes.addx, addRQx).then((resx) => {
                addedReqs+=1
                var tmpresponseData = reqRes.addx[reqRes.addx.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.addx += 1
                }
              })
            }
          }
          if (reqErr.addx > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.addx[0], reqErr.addx)
            //--------------
            if (addedReqs>0){
              await this.initFormData().then( respx => {
                this.showLoadx(false, 1)
              })
              return false 
            }
            //--------------
            this.showLoadx(false, 1) 
            return false
          }
        }
        // Terminamos
        // -----------
        this.frmDataOriginal = JSON.parse(JSON.stringify(this.frmData))
        // alert('prueba avance')
        allowNextPage = true
      } else { // seguimos a la siguiente pantalla 
        test = 33
        allowNextPage = true
      }
      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if (this.currProfile_status < this.zstatUser.ESCOLARIDAD ){ // < 22 
        await this.hpb_updateProfileStatus( this.zstatUser.ESCOLARIDAD ).then(respx=>{
          retUPS = respx
        })
      }
      // -----------
      this.showLoadx(false, 1)
      if (allowNextPage) {
        var newRuta = '/dashboard/personal/aptitudes-b'
        this.$router.push(newRuta)
      }
      return true
    },
    async initFormData () {
      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }
      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, {
        categoria_id: '2,21,22,59,60,61', 'per-page': 'all', expand:'catalogo'
      }, true, null)

      await this.doRequestByPromise(this.$apiV2, 'pibData', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpij_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpij_Data))
        return false 
      }
      var profileData = reqRes.action[0].data.data
      this.profileRecords = JSON.parse(JSON.stringify(profileData))
      //----------------------------
      if (Array.isArray(profileData) && profileData.length>=1){ 
        // Manipulamos la info para llenar los datos en los valores 
        // alert('llenando datos')
        var val_grado = null
        var val_titulo = null
        var val_escuela = null
        var val_carrera = null
        var val_fechaIni = null
        var val_TipoFin = null 
        var val_FechaFin = null
        var valIdiomasx = []
        var val_idiomaOtro = null
        //----------------------------
        profileData.forEach(itemP => {
          if (itemP.categoriaId === 21) { // 21 grado
            val_grado = itemP.catalogo_id
          } else if (itemP.categoriaId === 22) { // 22 titulo
            val_titulo = itemP.catalogo_id
          } else if (itemP.categoriaId === 59) { // Datos escolaridad
            if (itemP.catalogo_id===748){  // Nombre Institución
              val_escuela = itemP.otro
            } else if (itemP.catalogo_id===749){ // Nombre Carrera
              val_carrera = itemP.otro
            }
          } else if (itemP.categoriaId === 60) { // Inicio estudios
            if (itemP.catalogo_id===750){
              val_fechaIni = itemP.otro
            }
          } else if (itemP.categoriaId === 61) { // Fin de estudios
            val_TipoFin  = itemP.catalogo_id
            if (itemP.catalogo_id===753){
              val_FechaFin = itemP.otro
            }
          } else  if (itemP.categoriaId === 2) { //Idiomas
              valIdiomasx.push(itemP.catalogo_id)
              if (itemP.catalogo_id === 8){ 
                val_idiomaOtro = '' + itemP.otro 
              }
          }
        })
        //----------------------------
        var newData = {
            grado: val_grado, 
            titulo: val_titulo, 
            escuela: val_escuela,
            carrera: val_carrera,
            fechaInicio:{ fecha:'' , year: null, month: null },
            tipoFin: val_TipoFin,
            fechaFin:{ fecha:'' , year: null, month: null },
            idiomas: valIdiomasx,
            idiomas_otros: val_idiomaOtro
        }
        if (val_fechaIni !== null){
          var fechasValsIni = []
          fechasValsIni = val_fechaIni.split('-') 
          newData.fechaInicio = {
            fecha: (val_fechaIni.length !== 10) ? '' : val_fechaIni,
            year: (val_fechaIni.length !== 10) ? null : parseInt(fechasValsIni[0]) ,
            month: (val_fechaIni.length !== 10) ? null : parseInt(fechasValsIni[1])
          }
        }
        if (val_FechaFin !== null){
          var fechasValsFin = []
          fechasValsFin = val_FechaFin.split('-') 
          newData.fechaFin = {
            fecha: (val_TipoFin!==753) ? '' : val_FechaFin,
            year: (val_TipoFin!==753) ? null : parseInt(fechasValsFin[0]) ,
            month: (val_TipoFin!==753) ? null : parseInt(fechasValsFin[1])
          }
        }

        this.frmDataOriginal = JSON.parse(JSON.stringify(newData))
        if (newData.idiomas.indexOf(3)<0) { // NO tiene idioma 
          newData.idiomas.push(3)
        }
        this.frmData = JSON.parse(JSON.stringify(newData))

      } else { // No hay info todavia 
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpij_Data))
        if (tplpij_Data.idiomas.indexOf(3)<0) { // NO tiene idioma 
          tplpij_Data.idiomas.push(3)
        }
        this.frmData = JSON.parse(JSON.stringify(tplpij_Data))
      }
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_escolaridad --   beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_escolaridad --   created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_escolaridad --    beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_escolaridad --   mounted')
    this.zloadingData = false

    this.showLoadx(true, 2)

    this.pij_createYearDataList() // Crea la lista años 
    var categorias = [2, 21, 22, 59, 61]

    if (this.$refs.fldx_fechainicio){
      this.$refs.fldx_fechainicio.reset()
    }
    if (this.$refs.fldx_fechafin){
      this.$refs.fldx_fechafin.reset()
    }
    if (this.$refs.fldx_idiomasall){
      this.$refs.fldx_idiomasall.reset()
    }

    for (let categx of categorias) { 
      if (this.getHelperCat(categx).dataLoaded===false){ 
        var testCatA = false 
        if (categx === 21) {
          await this.helpermix_getCatalog (categx, null, true).then( respx => { 
            this.$cannaDebug('-- psiPage_sociales -- categoria ' + categx  + ' finish -- sort ID ')
            testCatA = respx 
          })
        } else {
          await this.helpermix_getCatalog (categx, null).then( respx => { 
            this.$cannaDebug('-- psiPage_sociales -- categoria ' + categx  + ' finish ')
            testCatA = respx 
          })
        }
      }
      await this._waitRequestProc(100)
    }
    await this._waitRequestProc(200)

    //----------- Grado de estudios  
    var allowedVals = []
    this.getHelperCat(21).dataCatalog.forEach( item => { allowedVals.push(item.id) })
    this.z_allowGrados = allowedVals.slice(0)
    allowedVals = []
    //-----------  Comprobante de estudios Titulos
    this.getHelperCat(22).dataCatalog.forEach( item => { allowedVals.push(item.id) })
    this.z_allowTitulos = allowedVals.slice(0)
    allowedVals = []
    //-----------  Fin de estudios
    this.getHelperCat(61).dataCatalog.forEach( item => { allowedVals.push(item.id) })
    this.z_allowfinEstudios = allowedVals.slice(0)
    allowedVals = []
    // ----------------
    await this._waitRequestProc(200)
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.$forceUpdate()

    if (this.$refs.fldx_fechainicio){
      this.$refs.fldx_fechainicio.reset()
    }
    if (this.$refs.fldx_fechafin){
      this.$refs.fldx_fechafin.reset()
    }
    if (this.$refs.fldx_idiomasall){
      this.$refs.fldx_idiomasall.reset()
    }

    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_escolaridad --   beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_escolaridad --   updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_escolaridad --   activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)

      await this._waitRequestProc(200)
      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      this.$forceUpdate()

      await this._waitRequestProc(200)

      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_escolaridad --   deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_escolaridad --   beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_escolaridad --   destroyed')
  }
}
</script>
